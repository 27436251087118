import React, {useRef, useState, useEffect} from 'react';
import useClickOutside from 'hooks/useClickOutside';
import styles from './dropdown.module.scss';
import useScrollOutside from 'hooks/useScrollOutside';

const DropDown = (props: {
  children: React.ReactNode;
  className?: string;
  minWidth?: number;
  onClickOutside?: (e?: MouseEvent) => void;
  onScrollOutside?: () => void;
  matchParentWidth?: boolean;
  anchorToRight?: boolean;
  ignoreMaxWidth?: boolean;
  lockScroll?: boolean;
  ignoreTop?: boolean;
  ignoreLeft?: boolean;
  disableFixedPosition?: boolean;
}) => {
  const {
    children,
    className,
    onClickOutside,
    onScrollOutside,
    minWidth = 100,
    matchParentWidth,
    anchorToRight = false,
    ignoreMaxWidth = false,
    lockScroll = true,
    ignoreTop = false,
    ignoreLeft = false,
    disableFixedPosition = false,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClickOutside);
  useScrollOutside(ref, () => onScrollOutside && onScrollOutside());
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const parent = ref.current.parentElement;
      if (!parent) {
        return;
      }

      const container = document.body;
      if (container && lockScroll) {
        container.style.overflow = 'hidden';
      }
      const scrollModal = document.getElementById('ModalScrollContainer');
      if (scrollModal) {
        scrollModal.style.overflow = 'hidden';
      }

      const parentRect = parent.getBoundingClientRect() as DOMRect;
      const top = parentRect.y + parentRect.height;

      const vWidth = document.documentElement.clientWidth;

      // the dropdown shouldn't allow itself to extend beyond the window width
      let maxWidthVal = vWidth - parentRect.x;

      let forceAnchorRight = false;

      // If maxWidth is less than the minimum width declared, anchor to the
      // right of the parent instead of theleft
      if (maxWidthVal < minWidth) {
        maxWidthVal = minWidth;
        forceAnchorRight = true;
      }

      // We add an extra 10px so we are clear of the edge of the page +
      // scrollbars.
      maxWidthVal -= 10;

      const maxHeight = `calc(100vh - ${top}px)`;
      const maxWidth = `${maxWidthVal}px`;
      if (!ignoreTop) ref.current.style.top = `${top}px`;

      if (anchorToRight) {
        ref.current.style.right = `calc(100vw - ${parentRect.right}px)`;
      } else if (forceAnchorRight && !ignoreLeft) {
        ref.current.style.left = `calc(${parentRect?.x}px + (${parentRect?.width}px - ${maxWidthVal}px - 10px)`;
      } else if (!ignoreLeft) {
        ref.current.style.left = `${parentRect?.x}px`;
      }
      ref.current.style.maxHeight = maxHeight;
      if (!ignoreMaxWidth) {
        ref.current.style.maxWidth = maxWidth;
      }
      ref.current.style.minWidth = `${minWidth}px`;

      if (matchParentWidth) {
        ref.current.style.width = `${parentRect?.width}px`;
      }
      setDisplay(true);
    }

    return () => {
      const container = document.body;
      if (container) {
        container.style.overflow = 'auto';
      }
      const scrollModal = document.getElementById('ModalScrollContainer');
      if (scrollModal) {
        scrollModal.style.overflow = 'auto';
      }
    };
  }, [minWidth]);

  const classNamesArray = [styles.dropdown];

  if (className) {
    className.split(' ').forEach((c) => classNamesArray.push(c));
  }

  if (display) {
    classNamesArray.push(styles.show);
  }

  if (anchorToRight) {
    classNamesArray.push(styles.rightAnchor);
  }
  if (!disableFixedPosition) {
    classNamesArray.push(styles.fixedPosition);
  }

  return (
    <div className={classNamesArray.join(' ')} ref={ref}>
      {children}
    </div>
  );
};

export default DropDown;
