import {useEffect} from 'react';

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback?: (e?: MouseEvent) => void,
) => {
  const handleClick = (e: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !ref.current.isSameNode(e.target as Node)
    ) {
      // call the callback if one exists
      callback?.(e);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
};

export default useClickOutside;
