import {useEffect} from 'react';

const useScrollOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) => {
  const handleScroll = (e: MouseEvent) => {
    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !ref.current.isSameNode(e.target as Node)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', handleScroll);

    return () => {
      document.removeEventListener('wheel', handleScroll);
    };
  }, []);
};

export default useScrollOutside;
