import gql from 'graphql-tag';
import {LOGGED_IN_USER_FIELDS} from 'queries/user';
import {Seller, User} from 'types';

export interface ActivateAccountMutationData {
  activateAccount: {
    id: string;
    success: boolean;
    signerEmail: string;
    redirectPath: string;
    seller: Seller;
    message: string;
  };
}

export const activateAccount = gql`
  mutation activateAccount($username: String!, $oneTimeToken: String!) {
    activateAccount(username: $username, oneTimeToken: $oneTimeToken) {
      id
      success
      signerEmail
      redirectPath
      seller {
        id
        name
      }
      message
    }
  }
`;

export interface SignUpMutationData {
  signUp: {
    success: boolean;
    orderId: string;
    redirectPath: string;
    error: string;
    passwordError: string[];
  };
}

export const signUpMutation = gql`
  ${LOGGED_IN_USER_FIELDS}
  mutation signUp(
    $payload: String!
    $orderInput: OrderInput!
    $lineItems: [OrderFormLineItemsInput]!
    $file: Upload
  ) {
    signUp(
      payload: $payload
      orderInput: $orderInput
      lineItems: $lineItems
      file: $file
    ) {
      success
      redirectPath
      error
      passwordError
      orderId
      user {
        ...LoggedInUserFields
      }
    }
  }
`;

export interface LogInMutationData {
  logIn: {
    id: string;
    success: boolean;
    redirectPath: string;
    seller: Seller;
    user: User;
  };
}

export const logIn = gql`
  ${LOGGED_IN_USER_FIELDS}
  mutation logIn($payload: String!) {
    logIn(payload: $payload) {
      id
      success
      redirectPath
      seller {
        id
        name
      }
      user {
        ...LoggedInUserFields
      }
    }
  }
`;

export const logOut = gql`
  mutation logOut {
    logOut {
      success
    }
  }
`;

export const resetPassword = gql`
  mutation resetPassword(
    $username: String!
    $newPassword: String!
    $passwordResetToken: String
    $password: String
  ) {
    resetPassword(
      username: $username
      passwordResetToken: $passwordResetToken
      newPassword: $newPassword
      password: $password
    ) {
      success
      message
    }
  }
`;

export interface ResetPasswordData {
  resetPassword: {
    success: boolean;
    message: string;
  };
}

export const startPasswordReset = gql`
  mutation startPasswordReset($username: String!) {
    startPasswordReset(username: $username) {
      success
    }
  }
`;

export interface StartPasswordResetData {
  startPasswordReset: {
    success: boolean;
  };
}

export const startTeammatePasswordReset = gql`
  mutation startTeammatePasswordReset($username: String!) {
    startTeammatePasswordReset(username: $username) {
      success
    }
  }
`;

export interface StartTeammatePasswordResetData {
  startTeammatePasswordReset: {
    success: boolean;
  };
}

export const useCreateAuthPayload = (
  username: string,
  password: string,
): string => {
  return btoa(JSON.stringify({username, password}));
};
